import {Dropdown, Menu, Tag} from 'antd';
import moment from 'moment';
import {toast} from 'react-toastify';
import {
  computeFeeFromFeeSchedule,
  convertToNumber,
  extractCompanyId,
  extractUserId,
  formatValue,
  sanitizeInput,
} from 'utils/helper';

const handleStocksOrderFilter = (
  values,
  setFilterValues,
  page,
  user,
  refetch,
  activeTab,
  limit,
  searchTicker,
  searchedValue,
  isAffiliate,
  setOpenFilterModal,
) => {
  setFilterValues(values);
  let {
    limitPriceLessThan,
    limitPriceGreaterThan,
    companyId,
    startDate,
    status,
    endDate,
    collection,
    stockExchange,
    collectionId,
    startExpiryDate,
    instrumentKey,
    instrumentId,
    affiliateOrder,
    endExpiryDate,
    type,
    direction,
    startSettlementDate,
    endSettlementDate,
    startFillDate,
    endFillDate,
  } = values;
  const formattedValues = {
    limitPriceLessThan: convertToNumber(limitPriceLessThan),
    limitPriceGreaterThan: convertToNumber(limitPriceGreaterThan),
    companyId: extractCompanyId(companyId),
    userId: extractUserId(user),
    startDate: formatValue(startDate, d => moment(d).format()),
    endDate: formatValue(endDate, d => moment(d).format()),
    startExpiryDate: formatValue(startExpiryDate, d => moment(d).format()),
    endExpiryDate: formatValue(endExpiryDate, d => moment(d).format()),
    startSettlementDate: formatValue(startSettlementDate, d =>
      moment(d).format(),
    ),
    endSettlementDate: formatValue(endSettlementDate, d => moment(d).format()),
    startFillDate: formatValue(startFillDate, d => moment(d).format()),
    endFillDate: formatValue(endFillDate, d => moment(d).format()),
  };

  refetch({
    input: {
      status: status
        ? status
        : ['PENDING', 'FILLED', 'EXPIRED'].includes(activeTab)
        ? activeTab
        : null,
      limit,
      collection,
      collectionId,
      affiliateOrder,
      stockExchange,
      instrumentKey,
      instrumentId,
      type,
      direction,
      ...formattedValues,
      search: searchTicker || searchedValue || null,
      page: page ?? 1,
      'paginate': true,
      'newFirst': true,
      ...(isAffiliate && {affiliateOrder: isAffiliate}),
      includeOrdersWithElapsedExpiry: true,
      openOrders:
        activeTab === 'ACTIVE' && !['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? true
          : [
              'PARTIALLY_FILLED',
              'REJECTED',
              'OPEN',
              'ERROR',
              'CANCELLED',
              'SETTLED',
            ].includes(status) ||
            ['PENDING', 'FILLED', 'EXPIRED'].includes(activeTab)
          ? null
          : false,
    },
  });
  setOpenFilterModal(false);
};

const handleStocksOrdersColumns = (
  navigate,
  styles,
  gamer,
  tabOperations,
  handleTab,
  dotsIcon,
) => {
  return [
    {
      title: 'Order ID',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => <b>{record?.id}</b>,
    },

    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      render: (text, record) => (
        <div
          onClick={() =>
            navigate(
              `/dashboard/user/${record?.user?.id}/transactions?tab=stock`,
            )
          }
          className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record.id}`)}
            className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamer} alt="" />
            )}
            <h4>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Ticker',
      key: 'ticker',
      dataIndex: 'ticker',
      render: (text, render) => <span>{render?.symbol}</span>,
    },
    {
      title: 'Direction',
      key: 'direction',
      dataIndex: 'direction',
    },

    {
      title: 'Instrument',
      key: 'instrumentKey',
      dataIndex: 'instrumentKey',
      render: (text, render) => (
        <Tag
          color={
            {
              'BOND': 'cyan',
              'STOCK': 'red',
              'PUBLIC_EQUITY': 'orange',
            }[render?.instrumentKey]
          }>
          {render?.instrumentKey}
        </Tag>
      ),
    },
    {
      title: 'Currency',
      key: 'assetCurrency',
      dataIndex: 'assetCurrency',
    },
    {
      title: 'Order Type',
      key: 'type',
      dataIndex: 'type',
      render: (text, render) => (
        <Tag color={render?.type === 'LIMIT' ? 'black' : 'default'}>
          {render?.type}
        </Tag>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'FILLED': 'green',
              'PENDING': 'orange',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'EXPIRED': 'red',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Collection',
      key: 'collectionId',
      dataIndex: 'collectionId',
      render: (text, record) =>
        record?.collection ? (
          <div className={styles['record-label']}>
            <div className={styles['record-name']}>
              <img src={record?.collection?.bannerUrl} />
              <h4>{record?.collection?.name}</h4>
            </div>
          </div>
        ) : (
          <span>- -</span>
        ),
    },
    {
      title: 'Stock Price',
      key: 'stockPrice',
      dataIndex: 'stockPrice',
      render: (text, render) => (
        <span>{render?.limitPrice?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Fill Price',
      key: 'avgFillPrice',
      dataIndex: 'avgFillPrice',
      render: (text, render) => (
        <span>{render?.avgFillPrice?.toLocaleString()}</span>
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Order Total (Ex. Fees)',
      key: 'price',
      dataIndex: 'price',
      render: (text, render) => (
        <span>
          {(
            render?.quantity * (render?.avgFillPrice || render.limitPrice)
          ).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Static Fee',
      key: 'staticFee',
      dataIndex: 'staticFee',
    },
    {
      title: 'Floating Fee',
      key: 'FloatingFee',
      dataIndex: 'floatingFee',
      render: (text, render) => (
        <span>
          {computeFeeFromFeeSchedule(render?.feeSchedule, render?.price, {
            skipFixed: true,
            skipStatic: true,
            skipVariable: true,
          })}
        </span>
      ),
    },
    {
      title: 'Fee Charged',
      key: 'feeCharged',
      dataIndex: 'feeCharged',
      render: (text, render) => <span>{render?.feeCharged?.toFixed(2)}</span>,
    },
    {
      title: 'Expiration Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => (
        <span>
          {record?.expiresAt
            ? moment(record?.expiresAt).format('DD MMMM, YYYY h:mmA')
            : ''}
        </span>
      ),
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMMM, YYYY h:mmA')}</span>
      ),
    },
    {
      title: 'Filled Date',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text, record) =>
        record?.lastFillDate ? (
          <span>
            {moment(record?.lastFillDate).format('DD MMMM, YYYY h:mmA')}
          </span>
        ) : (
          ''
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (text, render) => (
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              {tabOperations?.update ? (
                <>
                  {['OPEN', 'PARTIALLY_FILLED'].includes(render?.status) ? (
                    <>
                      <Menu.Item
                        key="1"
                        onClick={() => handleTab('REJECT', render.id)}>
                        <div>
                          <span>Reject Order</span>
                        </div>
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={e => handleTab('SETTLE', render.id, e)}>
                        <div>
                          <span>Fill Order</span>
                        </div>
                      </Menu.Item>
                    </>
                  ) : render?.status === 'FILLED' ? (
                    <Menu.Item
                      key="2"
                      onClick={e => handleTab('FILLED', render, e)}>
                      <div>
                        <span>Settle Order</span>
                      </div>
                    </Menu.Item>
                  ) : render?.status === 'PENDING' ? (
                    <>
                      <Menu.Item
                        onClick={e => handleTab('PENDING', render.id, e)}>
                        Open Order
                      </Menu.Item>
                      <Menu.Item
                        key="2"
                        onClick={() => handleTab('REJECT', render.id)}>
                        Reject Order
                      </Menu.Item>
                    </>
                  ) : (
                    <></>
                  )}
                  {render.status === 'OPEN' && (
                    <>
                      <Menu.Item
                        key="6"
                        onClick={e => handleTab('OPEN', render.id, e)}>
                        <div>
                          <span>Revert to Pending</span>
                        </div>
                      </Menu.Item>
                    </>
                  )}
                  {render?.status !== 'PENDING' && (
                    <Menu.Item
                      onClick={e => handleTab('HISTORY', render?.history, e)}
                      key="5">
                      View History
                    </Menu.Item>
                  )}
                </>
              ) : (
                <Menu.Item>No Action</Menu.Item>
              )}
              <>
                {render?.status !== 'PENDING' && (
                  <Menu.Item
                    key="10"
                    onClick={() => handleTab('REWRITE', render.id)}>
                    Rewrite Order
                  </Menu.Item>
                )}
              </>
              {['PARTIALLY_FILLED', 'OPEN'].includes(render?.status) &&
                render?.type === 'LIMIT' && (
                  <Menu.Item
                    key="7"
                    onClick={e =>
                      handleTab('OPEN', {id: render.id, type: 'EXPIRE'}, e)
                    }>
                    <div>
                      <span>Expire Order</span>
                    </div>
                  </Menu.Item>
                )}
              <Menu.Item
                onClick={e =>
                  handleTab('NOTES', {notes: render?.notes, id: render?.id}, e)
                }
                key="8">
                View / Edit Notes
              </Menu.Item>
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];
};

const handleSearchStockTicker = (
  searchTerm,
  user,
  active,
  pageLimit,
  existingFilters,
  refetch,
  currentPage,
  isAffiliate,
  handleResetPage,
) => {
  // Check for double preceding whitespace or double whitespace
  const sanitizedValue = sanitizeInput(searchTerm);

  let {
    companyId,
    startDate,
    endDate,
    collection,
    collectionId,
    startExpiryDate,
    endExpiryDate,
    status,
    type,
    affiliateOrder,
    stockExchange,
    instrumentKey,
    instrumentId,
    direction,
    startSettlementDate,
    endSettlementDate,
    startFillDate,
    endFillDate,
  } = existingFilters;

  const formattedValues = {
    companyId: extractCompanyId(companyId),
    userId: extractUserId(user),
    startDate: formatValue(startDate, d => moment(d).format()),
    endDate: formatValue(endDate, d => moment(d).format()),
    startExpiryDate: formatValue(startExpiryDate, d => moment(d).format()),
    endExpiryDate: formatValue(endExpiryDate, d => moment(d).format()),
    startSettlementDate: formatValue(startSettlementDate, d =>
      moment(d).format(),
    ),
    endSettlementDate: formatValue(endSettlementDate, d => moment(d).format()),
    startFillDate: formatValue(startFillDate, d => moment(d).format()),
    endFillDate: formatValue(endFillDate, d => moment(d).format()),
  };

  handleResetPage();
  refetch({
    input: {
      ...formattedValues,
      collection,
      collectionId,
      type,
      direction,
      instrumentKey,
      stockExchange,
      instrumentId,
      affiliateOrder,
      status: ['PENDING', 'FILLED', 'EXPIRED'].includes(status || active)
        ? status || active
        : null,
      search: sanitizedValue.toUpperCase() ?? null,
      page: currentPage,
      'paginate': true,
      limit: pageLimit,
      'newFirst': true,
      ...(isAffiliate && {affiliateOrder: isAffiliate}),
      includeOrdersWithElapsedExpiry: true,
      openOrders:
        active === 'ACTIVE' && !['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? true
          : ['PENDING', 'FILLED', 'EXPIRED'].includes(active) ||
            ['PARTIALLY_FILLED', 'OPEN'].includes(status)
          ? null
          : false,
    },
  });
};

const handleOrderRewrite = ({
  rewriteOrder,
  refetch,
  setOrder,
  setOrderModal,
  orderId,
}) => {
  rewriteOrder({
    variables: {
      orderId,
    },
  })
    .then(({data: {rewrite}}) => {
      if (rewrite.statusCode === 400 || rewrite.__typename === 'Error') {
        toast.error(rewrite?.message);
      } else {
        toast.success('Order Rewrite Successful');
        refetch();
        setOrder('');
        setOrderModal(false);
      }
    })
    .catch(error => toast.error(error));
};

export {
  handleStocksOrderFilter,
  handleOrderRewrite,
  handleStocksOrdersColumns,
  handleSearchStockTicker,
};
