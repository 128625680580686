import './App.scss';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {ToastContainer, Flip} from 'react-toastify';

// import Register from './pages/user/register/index';
import Login from './pages/user/login/index';

import {useSelector} from 'react-redux';
// layout
import {AdminDashboardLayout} from './layout/dashboard';

import {
  AdminDashboardHome,
  AdminReports,
  AdminGroups,
  AdminStartup,
  AdminStock,
  AdminPlans,
  Notifications,
  AdminUser,
  AdminSupportComplaint,
  Campaigns,
  AdminSupportFaq,
  ViewFaq,
  EditFaq,
  NewFaq,
  BetaAccessCode,
  AdminKeyStat,
  AdminMobileSettings,
  NotificationClass,
  DocumentsCenter,
} from 'pages';
import InvestorUpdates from 'components/investorUpdate';
import CreateGroup from 'pages/adminGroups/new/CreateGroup';
import EditGroup from 'pages/adminGroups/edit/EditGroup';
import UserRoutes from 'pages/adminUser/userDetails/userRoutes';
import InterestRoutes from 'pages/adminInterests/interestsRoutes';
import NewNotification from 'pages/notifications/newNotification';
import {
  CampaignLiveRoutes,
  CampaignTestRoutes,
} from 'pages/campaigns/new/CampaignRoutes';
import NewReport from 'pages/adminReport/newReport/newReport';
import CompanyReportRoutes from 'pages/adminReport/company/companyReportRoutes';
import CountryReportRoutes from 'pages/adminReport/country/countryReportRoutes';
import IndustryReportRoutes from 'pages/adminReport/industry/industryReportRoutes';
import PlanRoutes from 'pages/adminPlans/routes/PlanRoutes';

import {
  UpdateLiveCampaignRoutes,
  UpdateTestCampaignRoutes,
} from 'pages/campaigns/update/UpdateCampaignRoutes';
import NetPromoter from 'pages/adminNPS';
import UserScore from 'pages/adminNPS/userScore';
import CampaignUsersInterested from 'pages/campaigns/new/screens/campaignUsersInterested/CampaignUsersInterested';
import {Announcements} from 'pages/adminAnnouncement/announcementRoute';
import OfferingTransactions from 'pages/campaigns/transactions/OfferingTransactions';
// import MobileSetting from 'pages/mobileSetting/MobileSetting';
import {UploadUserDocument} from 'pages/adminUser/userDetails/tabs/documents/uploadDocument';
import {ViewUserDocument} from 'pages/adminUser/userDetails/tabs/documents/viewDocument';
import UpdateCampaignUserTrade from 'pages/campaigns/transactions/updateTransaction/UpdateCampaignUserTrade';
import BankDetails from 'pages/BankSettings';
import CreateWiredAccount from 'pages/BankSettings/createWiredAccount/CreateWiredAccount';
import UpdateWiredAccount from 'pages/BankSettings/updateAccount/UpdateWiredAccount';
import CryptoDetails from 'pages/cryptoSettings';
import AddCryptoAccount from 'pages/cryptoSettings/addCryptoAccount/AddCryptoAccount';
import UpdateCryptoAccount from 'pages/cryptoSettings/updateCryptoAccount/UpdateCryptoAccount';
import {UserReferral} from 'pages/usersReferral';
// import {UpdateReferralProgram} from 'pages/referralProgram/updateReferral';
import AddUsersToGroup from 'pages/adminGroups/addUsers/AddUsersToGroup';
import Fallback from 'components/Error/ErrorBoundary/404/Fallback';
import NewsPageRoutes from 'pages/news/NewsPageRoutes';
import LanguageVersion from 'pages/campaigns/languageVersion/languageVersion';
import UserStockTrade from 'pages/stocks/UserStockTrade';
import Wallet from 'pages/wallet/Wallet';
import WalletDetails from 'pages/wallet/view/WalletDetails';
import WalletTransaction from 'pages/wallet/transactions/WalletTransaction';
import UpdateWalletTransaction from 'pages/wallet/transactions/UpdateWalletTransaction/UpdateWalletTransaction';
import CurrencyDetails from 'pages/wallet/view/CurrencyFields';
import CreateProviderSettings from 'pages/wallet/create/CreateProviderSettings';
import CreateWalletCurrency from 'pages/wallet/create/CreateWalletCurrency';
import MobileMoneySettings from 'pages/wallet/mobileMoney/MobileMoneySettings';
import UpdateMobileMoneyOperator from 'pages/wallet/mobileMoney/update/UpdateMobileMoneyOperator';
import FxExchangeRate from 'pages/wallet/Swap/FxExchangeRate';
import CreateFXExchangeRate from 'pages/wallet/Swap/create/CreateFXExchangeRate';
import Payouts from 'pages/dividends/Payouts/ Payouts';
import DocumentCenter from 'pages/documentCenter/DocumentCenter';
import CreateDocumentCenter from 'pages/documentCenter/create/CreateDocumentCenter';
import DepositSettings from 'pages/depositSettings/DepositSettings';
import CreateDepositSettings from 'pages/depositSettings/create/CreateDepositSettings';
import CreateSeededCompanyDocument from 'pages/documentCenter/create/seededDocument/CreateSeededDocument';
import SettleOrder from 'pages/adminOpportunities/stocksListings/SettleOrder/SettleOrder';
import ViewCompanyListing from 'pages/adminOpportunities/stocksListings/viewCompanyListing/ViewCompanyListing';
import Register from 'pages/user/register';
import AdminSettingOverview from 'layout/subLayout/adminDashboard/adminSettings/AdminSettingOverview';
import UserRoleSettings from 'layout/subLayout/adminDashboard/adminSettings/userRole/UserRoleSettings';
import OTPScreen from 'pages/OTPScreen/OTPScreen';
import {RewardSchemes} from 'pages/rewardSchemes/rewardSchemeRoutes';
import {RewardBanks} from 'pages/rewardBanks/rewardBankRoutes';
import SwapTransactions from 'pages/wallet/view/swap/SwapTransactions';
import CreateStockExchangeDocument from 'pages/adminOpportunities/stocksListings/documents/create/createStockExchangeDocument';
import {Collections} from 'pages/collections/collectionRoutes';
import CampaignReports from 'pages/campaigns/campaignReports/CampaignReports';
import Affiliates from 'pages/Affiliates/Affiliates';
import SavingsRoute from 'pages/Savings';
import {
  AssetManagementRoute,
  CreateBondRoute,
  UpdateBondRoutes,
} from 'pages/AssetManagement';
import {FinancialRoutes} from 'pages/financials/FinancialRoutes';

function App() {
  const adminDetails = useSelector(state => state.auth);
  const isLoggedIn = localStorage.getItem('login');

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/register" element={<Register />} />
          {/* <Route path="/register/username" element={<Username />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/sign-in/otp" element={<OTPScreen />} />
          {isLoggedIn === 'loggedIn' ||
          adminDetails.isAuthenticated === true ? (
            <>
              <Route path="/dashboard" element={<AdminDashboardLayout />}>
                <Route index element={<AdminDashboardHome />} />
                <Route
                  path="/dashboard/admin-settings"
                  element={<AdminSettingOverview />}
                />
                <Route
                  path="/dashboard/create-role"
                  element={<UserRoleSettings />}
                />
                <Route
                  path="/dashboard/update-role/:id"
                  element={<UserRoleSettings />}
                />
                <Route
                  path="/dashboard/access-code"
                  element={<BetaAccessCode />}
                />
                <Route
                  path="/dashboard/announcements/*"
                  element={<Announcements />}
                />
                <Route path="/dashboard/stocks/*" element={<AdminStock />} />
                <Route
                  path="/dashboard/key-statistics/*"
                  element={<AdminKeyStat />}
                />
                <Route
                  path="/dashboard/startup/document-category"
                  element={<DocumentsCenter />}
                />
                <Route
                  path="/dashboard/opportunities/startups/*"
                  element={<AdminStartup />}
                />
                <Route path="/dashboard/nps" element={<NetPromoter />} />
                <Route path="/dashboard/nps/:id" element={<UserScore />} />
                <Route path="/dashboard/plans" element={<AdminPlans />} />
                <Route path="/dashboard/plans/*" element={<PlanRoutes />} />
                <Route path="/dashboard/groups" element={<AdminGroups />} />
                <Route
                  path="/dashboard/app-version-setting/*"
                  element={<AdminMobileSettings />}
                />
                <Route path="/dashboard/group/new" element={<CreateGroup />} />
                <Route
                  path="/dashboard/group/edit/:id"
                  element={<EditGroup />}
                />
                <Route
                  path="/dashboard/group/add-users/:id"
                  element={<AddUsersToGroup />}
                />
                <Route path="/dashboard/report" element={<AdminReports />} />
                <Route path="/dashboard/report/:slug" element={<NewReport />} />
                <Route path="/dashboard/support/">
                  <Route
                    path="/dashboard/support/complaint"
                    element={<AdminSupportComplaint />}
                  />
                  <Route
                    path="/dashboard/support/faq/*"
                    element={<AdminSupportFaq />}
                  />

                  <Route
                    path="/dashboard/support/faq/view/:id"
                    element={<ViewFaq />}
                  />
                  <Route
                    path="/dashboard/support/faq/edit/:id"
                    element={<EditFaq />}
                  />
                  <Route
                    path="/dashboard/support/faq/new"
                    element={<NewFaq />}
                  />
                </Route>
                <Route path="/dashboard/users" element={<AdminUser />} />
                <Route
                  path="/dashboard/user/:id/documents/upload-document"
                  element={<UploadUserDocument />}
                />
                <Route
                  path="/dashboard/user/:id/documents/upload-document/:documentId"
                  element={<UploadUserDocument />}
                />
                <Route
                  path="/dashboard/user/:id/documents/:documentId"
                  element={<ViewUserDocument />}
                />
                <Route
                  path="/dashboard/stocks/settle-order/:id"
                  element={<SettleOrder />}
                />
                <Route
                  path="/dashboard/user-referral"
                  element={<UserReferral />}
                />
                <Route
                  path="/dashboard/reward-schemes/*"
                  element={<RewardSchemes />}
                />
                <Route
                  path="/dashboard/reward-banks/*"
                  element={<RewardBanks />}
                />
                <Route
                  path="/dashboard/collections/*"
                  element={<Collections />}
                />
                <Route path="/dashboard/campaigns" element={<Campaigns />} />
                <Route
                  path="/dashboard/bank-details"
                  element={<BankDetails />}
                />
                <Route
                  path="/dashboard/update-account/:id"
                  element={<UpdateWiredAccount />}
                />
                <Route
                  path="/dashboard/banks/create-account/:type"
                  element={<CreateWiredAccount />}
                />
                <Route
                  path="/dashboard/crypto-setting"
                  element={<CryptoDetails />}
                />
                <Route
                  path="/dashboard/deposit-settings"
                  element={<DepositSettings />}
                />
                <Route
                  path="/dashboard/deposit-settings/create"
                  element={<CreateDepositSettings />}
                />
                <Route
                  path="/dashboard/deposit-settings/update/:id"
                  element={<CreateDepositSettings />}
                />
                <Route
                  path="/dashboard/create-crypto-account"
                  element={<AddCryptoAccount />}
                />
                <Route
                  path="/dashboard/update-crypto-account/:id"
                  element={<UpdateCryptoAccount />}
                />
                <Route
                  path="/dashboard/campaign/offering-transactions/:id/:companyId/:campaignId"
                  element={<OfferingTransactions />}
                />

                <Route
                  path="/dashboard/campaign/language-version/:id/"
                  element={<LanguageVersion />}
                />
                <Route
                  path="/dashboard/campaign/update-transaction/:id/:tradeId"
                  element={<UpdateCampaignUserTrade />}
                />
                <Route
                  path="/dashboard/campaign/users-interested/:id/:slug"
                  element={<CampaignUsersInterested />}
                />
                <Route
                  path="/dashboard/campaigns/new/*"
                  element={<CampaignLiveRoutes />}
                />
                <Route
                  path="/dashboard/campaign/update/*"
                  element={<UpdateLiveCampaignRoutes />}
                />
                <Route
                  path="/dashboard/campaign/:test/update/*"
                  element={<UpdateTestCampaignRoutes />}
                />
                <Route
                  path="/dashboard/campaigns/:slug/new/*"
                  element={<CampaignTestRoutes />}
                />
                <Route path="/dashboard/user/:id/*" element={<UserRoutes />} />
                <Route path="/dashboard/savings/*" element={<SavingsRoute />} />
                <Route
                  path="/dashboard/asset-management/*"
                  element={<AssetManagementRoute />}
                />
                <Route
                  path="/dashboard/asset-management/create/bond/:assetId/*"
                  element={<CreateBondRoute />}
                />
                <Route
                  path="/dashboard/asset-management/update/bond/:assetId/:id/:lang/*"
                  element={<UpdateBondRoutes />}
                />

                <Route
                  path="/dashboard/stocks/user-trade"
                  element={<UserStockTrade />}
                />
                <Route
                  path="/dashboard/stock-listing/companies/:code"
                  element={<ViewCompanyListing />}
                />
                <Route
                  path="/dashboard/explore/*"
                  element={<InvestorUpdates />}
                />
                <Route path="/dashboard/news/*" element={<NewsPageRoutes />} />

                <Route
                  path="/dashboard/report/company/:id/*"
                  element={<CompanyReportRoutes />}
                />
                <Route
                  path="/dashboard/report/country/:id/*"
                  element={<CountryReportRoutes />}
                />
                <Route
                  path="/dashboard/report/industry/:id/*"
                  element={<IndustryReportRoutes />}
                />

                <Route
                  path="/dashboard/interest/*"
                  element={<InterestRoutes />}
                />
                <Route
                  path="/dashboard/notifications"
                  element={<Notifications />}
                />

                <Route
                  path="/dashboard/notifications/class"
                  element={<NotificationClass />}
                />
                <Route path="/dashboard/wallet" element={<Wallet />} />
                <Route
                  path="/dashboard/fx-exchange-rate"
                  element={<FxExchangeRate />}
                />
                <Route
                  path="/dashboard/fx-exchange-rate/create"
                  element={<CreateFXExchangeRate />}
                />
                <Route
                  path="/dashboard/fx-exchange-rate/update/:id"
                  element={<CreateFXExchangeRate />}
                />

                <Route
                  path="/dashboard/wallet/create-provider-settings"
                  element={<CreateProviderSettings />}
                />
                <Route
                  path="/dashboard/document-center"
                  element={<DocumentCenter />}
                />
                <Route
                  path="/dashboard/document-center/new/:id"
                  element={<CreateDocumentCenter />}
                />
                <Route
                  path="/dashboard/stocks-listing/create"
                  element={<CreateStockExchangeDocument />}
                />
                <Route
                  path="/dashboard/listings/reports"
                  element={<CampaignReports />}
                />
                <Route
                  path="/dashboard/stocks-listing/update/:id"
                  element={<CreateStockExchangeDocument />}
                />
                <Route
                  path="/dashboard/document-center/create/seeded/:id"
                  element={<CreateSeededCompanyDocument />}
                />
                <Route
                  path="/dashboard/document-center/update/:id/:documentId"
                  element={<CreateDocumentCenter />}
                />

                <Route
                  path="/dashboard/wallet/create-wallet-currency"
                  element={<CreateWalletCurrency />}
                />
                <Route
                  path="/dashboard/wallet/swap-transactions/:currency"
                  element={<SwapTransactions />}
                />

                <Route
                  path="/dashboard/wallet/update-wallet-currency/:currency"
                  element={<CreateWalletCurrency />}
                />

                <Route path="/dashboard/affiliates" element={<Affiliates />} />

                <Route
                  path="/dashboard/wallet/view-details/:provider"
                  element={<WalletDetails />}
                />
                <Route
                  path="/dashboard/wallet/view-details/:provider/:currency"
                  element={<CurrencyDetails />}
                />
                <Route
                  path="/dashboard/wallet/mobile-money"
                  element={<MobileMoneySettings />}
                />
                <Route
                  path="/dashboard/wallet/mobile-money/:code"
                  element={<UpdateMobileMoneyOperator />}
                />
                <Route
                  path="/dashboard/wallet/transactions/:provider/:currency"
                  element={<WalletTransaction />}
                />
                <Route
                  path="/dashboard/financial/*"
                  element={<FinancialRoutes />}
                />
                <Route
                  path="/dashboard/wallet/transactions/all/:type/:currency"
                  element={<WalletTransaction />}
                />
                <Route
                  path="/dashboard/wallet/transactions/all/:currency"
                  element={<WalletTransaction />}
                />
                <Route
                  path="/dashboard/dividends/payouts"
                  element={<Payouts />}
                />
                <Route
                  path="/dashboard/wallet/user-transaction/:currency/:id/:userId"
                  element={<UpdateWalletTransaction />}
                />

                <Route
                  path="/dashboard/notifications/new"
                  element={<NewNotification />}
                />
              </Route>
              <Route path="*" element={<Fallback />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        toastClassName="toast"
        transition={Flip}
      />
    </>
  );
}

export default App;
