import {DatePicker, Form, Select, Switch} from 'antd';
import styles from './viewOrders.module.scss';
import {stockOrderStatus} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useLazyQuery, useQuery} from '@apollo/client';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {useEffect, useState} from 'react';
import moment from 'moment';
import {GET_ALL_COLLECTIONS} from 'graphql/queries/collections';
import {assetClassKeyTypes} from 'pages/AssetManagement/modules/constants';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import _ from 'lodash';
import {GET_ALL_STOCKS} from 'graphql/queries/stocks';

export const OrderFilter = ({
  handleFilter,
  loading,
  user,
  setUser,
  activeTab,
  handleClose,
  isFilterModalOpen,
  reset,
  isExport,
}) => {
  const [instruments, setInstruments] = useState([]);
  const [form] = Form.useForm();
  const instrumentKey = Form.useWatch('instrumentKey', form);
  const assetKey = Form.useWatch('assetKey', form);

  const {data: getAllCompanies} = useQuery(GET_ALL_STARTUPS, {
    skip: !isFilterModalOpen,
  });
  const {data: getAllCollections, loading: loadingCollections} = useQuery(
    GET_ALL_COLLECTIONS,
    {
      skip: !isFilterModalOpen,
      variables: {
        filters: {
          all: true,
        },
      },
    },
  );
  const {data: stockExchange, loading: stockLoading} = useQuery(
    GET_ALL_STOCKS,
    {
      skip: !isFilterModalOpen,
    },
  );

  const [
    fetchInstruments,
    {refetch: refetchInstrument, loading: loadingInstruments},
  ] = useLazyQuery(GET_ASSET_CLASS_INSTRUMENTS);

  useEffect(() => {
    if (instrumentKey && assetKey) {
      fetchInstruments({
        variables: {
          input: {
            explore: false,
            instrumentKey,
            assetClassKey: assetKey,
          },
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data =>
          setInstruments(data?.getFinancialInstruments?.data || []),
      });
    }
  }, [instrumentKey, assetKey]);

  const sortStatus = ['OPEN', 'PARTIALLY_FILLED'];

  const handleSearch = _.debounce(async value => {
    try {
      const {data} = await refetchInstrument({
        input: {
          explore: false,
          instrumentKey,
          assetClassKey: assetKey,
          search: value,
          page: 1,
          limit: 100,
        },
      });
      setInstruments(data.getFinancialInstruments?.data || []);
    } catch (error) {
      console.error('Error fetching instruments:', error);
    }
  }, 1200);

  useEffect(() => {
    form.resetFields();
  }, [activeTab]);

  const Fields = (
    <div className={styles.orderSort}>
      {!['PENDING', 'FILLED'].includes(activeTab) && !isExport && (
        <Form.Item
          className={isExport ? styles.smallWidth : ''}
          label="Status"
          name="status">
          <Select allowClear>
            {stockOrderStatus
              .filter(data =>
                activeTab === 'ACTIVE'
                  ? sortStatus.includes(data.key)
                  : !sortStatus.includes(data.key),
              )
              ?.map(data => (
                <Select.Option key={data.key}>{data.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <div className={styles.price}>
        <Form.Item
          className={styles.smallWidth}
          name="direction"
          label="Direction">
          <Select allowClear>
            <Select.Option key="BUY">BUY</Select.Option>
            <Select.Option key="SELL">SELL</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className={styles.smallWidth} name="type" label="Order Type">
          <Select allowClear>
            <Select.Option key="LIMIT">Limit</Select.Option>
            <Select.Option key="MARKET">Market</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className={styles.price}>
        <Form.Item
          className={styles.smallWidth}
          name="userId"
          label="Search & Select User">
          <UserSelectField
            users={user}
            setUsers={setUser}
            skip={!isFilterModalOpen}
            mode="single"
          />
        </Form.Item>
        <Form.Item
          className={styles.smallWidth}
          name="companyId"
          label="Company">
          <Select allowClear showSearch>
            {getAllCompanies?.getAllCompanies?.map(company => (
              <Select.Option key={`${company.name}~${company.id}`}>
                <div className="all-data">
                  <img src={company.logoImgURL} alt="" />
                  <span>{company.name}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <h3>Collections / Affiliate Orders</h3>
      <Form.Item name="collectionId" label="Select Collection">
        <Select
          loading={loadingCollections}
          optionFilterProp="label"
          allowClear
          showSearch>
          {getAllCollections?.adminGetAllCollections?.data?.collections?.map(
            collection => (
              <Select.Option label={collection?.name} key={collection?.id}>
                <div className="all-data">
                  <img src={collection?.iconUrl} alt="" />
                  <span>{collection?.name}</span>
                </div>
              </Select.Option>
            ),
          )}
        </Select>
      </Form.Item>
      <div className={styles.bool}>
        <Form.Item name="collection" label="Collection Orders">
          <Switch />
        </Form.Item>
        <Form.Item name="affiliateOrder" label="Affiliate Orders">
          <Switch />
        </Form.Item>
      </div>
      <h3>Time & Date Range</h3>
      <div className={styles.price}>
        <Form.Item
          name="startDate"
          className={styles.smallWidth}
          label="Start Date">
          <DatePicker
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item name="endDate" label="End Date">
          <DatePicker
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
      </div>
      {(['EXPIRE', 'PAST'].includes(activeTab) || isExport) && (
        <div className={styles.price}>
          <Form.Item name="startSettlementDate" label="Start Settlement Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endSettlementDate" label="End Settlement Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
      )}
      <div className={styles.price}>
        <Form.Item name="startFillDate" label="Start Fill Date">
          <DatePicker
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item name="endFillDate" label="End Fill Date">
          <DatePicker
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
      </div>
      {(['EXPIRE'].includes(activeTab) || isExport) && (
        <div className={styles.price}>
          <Form.Item name="startExpiryDate" label="Start Expiry Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endExpiryDate" label="End Expiry Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
      )}
      <Form.Item
        className={styles.field}
        name="stockExchange"
        label="Select Stock Exchange">
        <Select
          loading={stockLoading}
          placeholder="Select stock exchange"
          showSearch
          allowClear
          disabled={stockLoading}
          optionFilterProp="children">
          {stockLoading ? (
            <Select.Option disabled key="Loading">
              Loading...
            </Select.Option>
          ) : (
            stockExchange?.getAllStocksExchange?.map(item => (
              <Select.Option value={item?.id} key={item?.id}>
                {item.stockExchangeName}
              </Select.Option>
            ))
          )}
        </Select>
      </Form.Item>
      <>
        <h3>Financial Instrument</h3>
        <div className={styles.price}>
          <Form.Item
            className={styles.smallWidth}
            name="instrumentKey"
            label="Instrument Key">
            <Select placeholder="Bond" allowClear>
              <Select.Option key="BOND">Bond</Select.Option>
              <Select.Option key="STOCK">Stock</Select.Option>
              <Select.Option key="PUBLIC_EQUITY">Public Equity</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="assetKey"
            label="Asset Class">
            <Select allowClear>
              {assetClassKeyTypes.map(data => (
                <Select.Option key={data.value}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {instrumentKey && assetKey && (
          <Form.Item
            name="instrumentId"
            label="Instrument based on Selected Asset">
            <Select
              showSearch
              onSearch={handleSearch}
              filterOption={false}
              loading={loadingInstruments}
              allowClear>
              {instruments.map(instrument => (
                <Select.Option key={instrument?.id}>
                  {instrument?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    </div>
  );

  return (
    <div className={styles.order}>
      {!isExport && (
        <div className={styles.header}>
          <h1>Filter Orders</h1>
          <p role="button" onClick={handleClose}>
            X
          </p>
        </div>
      )}
      {isExport ? (
        Fields
      ) : (
        <Form
          onFinish={values => {
            handleFilter(values);
            reset();
          }}
          form={form}
          layout="vertical">
          {Fields}
          {!isExport && (
            <SubmitButton disabled={loading} label="Apply Changes" />
          )}
        </Form>
      )}
    </div>
  );
};
